.container {
  background-color: rgba(0, 0, 0, 0.8);
  // opacity: 0.8;
  padding: 16px;
  border-radius: 20px;
  max-width: 450px;
  .title {
    font-weight: bold;
    color: #ffc836;
  }
  .text {
    color: white;
  }
}