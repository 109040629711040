@import "../styles/responsive.scss";


.contentContainer {
  display: flex;
  flex-flow: column;
  padding: 16px 0;
  justify-content: center;
  .header {
    color: var(--primary-text);
    font-size: 84px;
    font-weight: bold;
    text-align: start;
    margin-bottom: 48px;
    span {
      display: contents;
    }
  }
  .text {
    color: var(--primary-text);
    text-align: start;
    text-indent: 32px;
    margin-bottom: 16px;
  }
  @include mobile {
    .header {
      font-size: 48px;
    }
  }
}

.imageContainer {
  height: auto;
  div {
    height: auto;
    img {
      height: auto;
      object-fit: contain;
    }
  }
  @include mobile {
    order: 1;
  }
}