@import '../styles/responsive.scss';

.container {
  // height: 1280px !important;
  background-position: bottom;
  margin-top: 10%;
  margin-bottom: 5%;

  .item {
    padding: 8px 0;
    @include mobile {
      justify-content: center;
    }
  }
}