a:link {
	color: #FFFFFF;
	background-color: transparent;
	text-decoration: none;
	text-align: center
}
a {
	color: #FFFFFF;
	background-color: transparent;
	text-decoration: none;
	text-align: center
}
/* .navbar {
	--bs-bg-opacity: 0.0
} */

body {
	margin: 0;
	font-family: "Quicksand";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

section {
	width: 100%;
	padding-left: 10%;
	padding-right: 10%;
}

@media only screen and (max-width: 767px) {
	/* For mobile phones: */
	.MobileCenterText {
	  text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
	}
	.MobileCenterNoMarginText {
	  text-align: center;
	}
  .NoMarginMobile {
    margin: 0px
  }
  .MobileNewLine {
    flex-direction: column;
  }
  .MobileShellTextMargin {
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 24px;
  }
}

@media only screen and (min-width: 768px) {
  .NonMobilePadding {
    padding-left: 15%;
    padding-right: 15%;
  }
}


@media only screen and (min-width: 1024px) {
	.AboutPaddingDesktop {
	  /* padding-top: 160px; */
	  padding-top: 10%;
	  padding-bottom: 5%;
	}
	.PaddingDesktop {
		padding-top: 32px;
	}
}