@import './styles/responsive.scss';

.containerWithBG {
  position: absolute;
  background-size: 100%;
  height: 150%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  
  &.bottom {
    align-self: flex-end;
  }
  &.top {
    align-self: flex-start;
  }
}

.faqContainer {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  @include largeDesktop {
    background-size: contain;
  }
}